/*.App {
  text-align: center;
}
button {
  margin-top: 10px;
  padding: 12px;
  color: #7ce5e8;
  background-color: transparent;
  border-radius: 100px;
  border-style: solid;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: large;
  border-color: #ccc;

  transition-duration: 350ms;
  transition-property: all;
}
button:disabled {
  opacity: 0.6;
  border-color: #aaa;
}
h2 {
  margin-left: 20px;
  margin-right: 20px;
}
p {
  margin-left: 20px;
  margin-right: 20px;
}
button:hover {
  opacity: 0.6;
  border-color: #aaa;
}
button:active {
  opacity: 0.9;
  border-color: #bbb;
}
iframe {
  width: 90vw;
  height: 90vh;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
*/
button {
  margin-top: 5px;
  padding: 15px;

  font-family: Arial, Helvetica, sans-serif;
  color: #fff !important;
  text-color: #000;

  border-radius: 100px;
  transition-duration: 350ms;
  transition-property: all;
  border-width: 2px;
  border-color: #3e3e3e;
  background-color: #7ce5e8;
}
.special {
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  background-color: #7ce5e8;
}
input {
  border-top-left-radius: 100px;
  border-bottom-left-radius: 100px;
}
button:disabled {
  border-color: #3e3e3e;
  color: #7ce5e8;
  background-color: #3e3e3e;
  opacity: 0.7;
}
button:disabled:hover {
  border-color: #3e3e3e;
  color: #7ce5e8;
  background-color: #3e3e3e;
  opacity: 0.7;
}
button:hover {
  background-color: #7ce5e8;
  opacity: 0.7;
  color: #fff;
}
button:active {
  background-color: #d18512;
  color: #fff;
}
.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
