button {
  margin: 5px;
  padding: 5px;

  font-family: Arial, Helvetica, sans-serif;
  color: #7ce5e8;

  border-radius: 100px;
  transition-duration: 350ms;
  transition-property: all;
  border-width: 2px;
  border-color: #3e3e3e;
}
.special {
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
input {
  border-radius: 100px;
  padding: 5px;
  color: black;
}
button:disabled {
  border-color: #3e3e3e;
  color: #7ce5e8;
  background-color: #3e3e3e;
  opacity: 0.7;
}
button:disabled:hover {
  border-color: #3e3e3e;
  color: #7ce5e8;
  background-color: #3e3e3e;
  opacity: 0.7;
}
button:hover {
  background-color: #7ce5e8;

  color: #fff;
}
button:active {
  background-color: #d18512;
  color: #fff;
}
.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
